import { Action, action, persist, Thunk, thunk } from 'easy-peasy'
import { NavigateFunction } from 'react-router-dom'
import csAPI from '../../../API/csConfig'
import { Color } from '../../../chess/types'
import { ColorSelection } from '../../../components/colorSelector/ColorSelector'
import { createQueryParams } from '../../../sharedComponents/src/globalHeader/common/queryParams'
import { analyticsManager } from '../../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import ReconnectingWebSocket from '../../../socket/ReconnectingWebsocket'
import { StoreModel } from '../../../store/store'
import {
    AIChallengeCreate,
    Bot,
    ChallengeEntry,
    ChallengeState,
    ConnectionStatus,
    GameResult,
    GameResultType,
    MMChallengeType,
    ScoreboardEntry,
    SearchOptions,
} from '../../../store/types'
import { getGameResultType } from '../../gameView/components/gameOverDialog/utils'
import { GameViewState, StateChangeRequest } from '../../gameView/GameViewModel'
import updateSelectedBotData from '../PlayBotsPanel/hooks/updateSelectedBotData'
import { MM_REQUESTS } from './MMTypes'

const removeItem = (challenges, challenge, identifier) => {
    const index = challenges.findIndex((item) => item[identifier] === challenge[identifier])
    if (index !== -1) challenges.splice(index, 1)
}

// ----------------------------------------------------------------------------- type

export type MatchMakingConnectionModel = {
    socket: ReconnectingWebSocket | undefined
    connectionStatus: ConnectionStatus
    searchOptions: SearchOptions
    rematchMatchData: {
        pairId: string
        userName: string
    }
    rematchStatus?: StateChangeRequest
    challengesToMe: ChallengeEntry[]
    autoChallengesByMe: ChallengeEntry[]
    customChallengesByMe: ChallengeEntry[]
    directChallengesByMe: ChallengeEntry[]
    openChallenges: ChallengeEntry[]
    onlinePresence: string
    alreadyConnected: boolean
    gameInQueue: boolean
    scoreboards: Array<ScoreboardEntry>
    bots: Array<Bot>
    openCustomBotPanel: boolean

    setRematchMatchData: Action<MatchMakingConnectionModel, { pairId: string; userName: string }>
    setRematchStatus: Action<MatchMakingConnectionModel, StateChangeRequest | undefined>
    setSearchOptions: Action<MatchMakingConnectionModel, SearchOptions>
    setSocket: Action<MatchMakingConnectionModel, ReconnectingWebSocket | undefined>
    setConnectionStatus: Action<MatchMakingConnectionModel, ConnectionStatus>
    setChallenges: Action<
        MatchMakingConnectionModel,
        {
            challengesToMe: ChallengeEntry[]
            challengesByMe: ChallengeEntry[]
            openChallenges: ChallengeEntry[]
            isFullRefresh?: boolean
        }
    >
    setOnlinePresence: Action<MatchMakingConnectionModel, string>
    setAlreadyConnected: Action<MatchMakingConnectionModel, boolean>
    setGameToQueue: Action<MatchMakingConnectionModel, boolean>
    setScoreboard: Action<MatchMakingConnectionModel, ScoreboardEntry>
    setBots: Action<MatchMakingConnectionModel, Array<Bot>>
    setStockfishBot: Action<MatchMakingConnectionModel>
    setOpenCustomBotPanel: Action<MatchMakingConnectionModel, boolean>
    getGameFromQueue: Action<MatchMakingConnectionModel>
    sendAIChallenge: Action<
        MatchMakingConnectionModel,
        {
            strength: number
            color: ColorSelection
            time: number
            navigate: NavigateFunction
            increment?: number
            fen?: string
            challengeeId?: string
        }
    >
    sendPlayerChallenge: Action<
        MatchMakingConnectionModel,
        {
            color: ColorSelection
            time: number
            increment?: number
            rated: boolean
            ratingFrom?: number
            ratingTo?: number
            FEN?: string
            challengeeId?: string
            challengeType: string
        }
    >
    sendRematchChallenge: Action<
        MatchMakingConnectionModel,
        {
            matchId: string
        }
    >
    sendDeleteChallenge: Action<MatchMakingConnectionModel, string>
    sendRejectChallenge: Action<MatchMakingConnectionModel, { pairId: string; isRematch: boolean }>
    acceptChallenge: Action<MatchMakingConnectionModel, { challengeType?: MMChallengeType; id: string }>
    getScore: Action<MatchMakingConnectionModel, { challengerId: string; challengeeId: string }>
    showGameOverDialog: Thunk<MatchMakingConnectionModel, undefined, {}, StoreModel>
    reset: Action<MatchMakingConnectionModel>
}

// ----------------------------------------------------------------------------- initial state

const matchMakingConnectionModel: MatchMakingConnectionModel = {
    socket: undefined,
    connectionStatus: ConnectionStatus.NOT_INITIALIZED,

    challengesToMe: [],
    autoChallengesByMe: [],
    customChallengesByMe: [],
    openChallenges: [],
    directChallengesByMe: [],
    searchOptions: {
        customChallenge: persist({
            minutes: 5,
            increment: 0,
            rated: true,
            color: ColorSelection.RANDOM,
            fullRatingRange: false,
        }, {
            storage: 'sessionStorage'
        }),
        directChallenge: persist({
            selectedChallenge: '3,0,blitz',
            rated: true,
            color: ColorSelection.RANDOM,
            customTime: false,
            minutes: 3,
            increment: 0,
        }, {
            storage: 'sessionStorage'
        }),
        computerChallenge: {
            selectedBot: undefined,
            selectedChallenge: '3,0,blitz',
            color: ColorSelection.RANDOM,
            strength: 5,
            customTime: false,
            minutes: 3,
            increment: 0,
        },
    },
    rematchMatchData: {
        pairId: '',
        userName: '',
    },
    onlinePresence: '',
    alreadyConnected: false,
    gameInQueue: false,
    scoreboards: [],
    bots: [],
    openCustomBotPanel: false,

    // ----------------------------------------------------------------------------- simple actions

    setSocket: action((state, socket) => {
        state.socket = socket
    }),

    setConnectionStatus: action((state, status) => {
        state.connectionStatus = status
    }),

    setChallenges: action((state, challengeObj) => {
        const { challengesByMe, challengesToMe } = challengeObj

        if (challengeObj.isFullRefresh) {
            challengesToMe?.forEach((challenge) => {
                if (challenge.challengeType === MMChallengeType.CUSTOM) {
                    state.openChallenges.unshift(challenge)
                } else {
                    state.challengesToMe.unshift(challenge)
                }
            })

            challengesByMe?.forEach((challenge) => {
                if (challenge.challengeType === MMChallengeType.CUSTOM) {
                    state.customChallengesByMe.unshift(challenge)
                } else if (challenge.challengeType === MMChallengeType.DIRECT) {
                    state.directChallengesByMe.unshift(challenge)
                } else if (challenge.challengeType === MMChallengeType.AUTO) {
                    state.autoChallengesByMe.unshift(challenge)
                }
            })

            return
        }

        challengesToMe?.forEach((challenge) => {
            switch (challenge.recordState) {
                case ChallengeState.ITEM_ADDED:
                    if (challenge.challengeType === MMChallengeType.CUSTOM) {
                        state.openChallenges.unshift(challenge)
                    } else {
                        state.challengesToMe.unshift(challenge)
                    }
                    break
                case ChallengeState.ITEM_REMOVED:
                    if (challenge.challengeType === MMChallengeType.CUSTOM) {
                        removeItem(state.openChallenges, challenge, 'challengeId')
                    } else {
                        removeItem(state.challengesToMe, challenge, 'pairId')
                    }
                    break
                default:
            }
        })

        challengesByMe?.forEach((challenge) => {
            switch (challenge.recordState) {
                case ChallengeState.ITEM_ADDED:
                    if (challenge.challengeType === MMChallengeType.CUSTOM) {
                        state.customChallengesByMe.unshift(challenge)
                    } else if (challenge.challengeType === MMChallengeType.DIRECT) {
                        state.directChallengesByMe.unshift(challenge)
                    } else if (challenge.challengeType === MMChallengeType.AUTO) {
                        state.autoChallengesByMe.unshift(challenge)
                    }
                    break
                case ChallengeState.ITEM_REMOVED:
                    if (challenge.challengeType === MMChallengeType.CUSTOM) {
                        removeItem(state.customChallengesByMe, challenge, 'challengeId')
                    } else if (challenge.challengeType === MMChallengeType.DIRECT) {
                        removeItem(state.directChallengesByMe, challenge, 'pairId')
                    } else if (challenge.challengeType === MMChallengeType.AUTO) {
                        removeItem(state.autoChallengesByMe, challenge, 'challengeId')
                    }
                    break
                default:
            }
        })
    }),

    setSearchOptions: action((state, searchOptions) => {
        state.searchOptions = searchOptions
    }),

    setRematchMatchData: action((state, rematchMatchData) => {
        state.rematchMatchData = rematchMatchData
    }),
    setRematchStatus: action((state, rematchStatus) => {
        state.rematchStatus = rematchStatus
    }),
    setOnlinePresence: action((state, onlinePresence) => {
        state.onlinePresence = onlinePresence
    }),
    setAlreadyConnected: action((state, alreadyConnected) => {
        state.alreadyConnected = alreadyConnected
    }),
    setGameToQueue: action((state, haveGameInQueue) => {
        state.gameInQueue = haveGameInQueue
    }),
    setScoreboard: action((state, data) => {
        const existingScoreboard = state.scoreboards.find((item) => item.pairId === data.pairId)
        if (!!existingScoreboard) {
            state.scoreboards = state.scoreboards.map((item) => {
                if (item.pairId === data.pairId) {
                    return data
                }
                return item
            })
        } else {
            state.scoreboards.push(data)
        }
    }),
    setBots: action((state, data) => {
        state.bots = data
        if (data.length > 0) {
            const selectedBot = data[0]
            state.searchOptions = updateSelectedBotData(state.searchOptions, selectedBot)
        }
    }),
    setStockfishBot: action((state) => {
        const bots = state.bots
        if (bots.length > 0) {
            const selectedBot = bots.find((bot) => bot.id === '00000000-0000-0000-0000-000000000006')
            if (selectedBot) {
                state.searchOptions = updateSelectedBotData(state.searchOptions, selectedBot)
            }
        }
    }),
    setOpenCustomBotPanel: action((state, value) => {
        state.openCustomBotPanel = value
    }),

    // ----------------------------------------------------------------------------- socket actions

    getGameFromQueue: action((state) => {
        state.alreadyConnected = false
        if (state.socket === undefined) return
        state.socket.send(
            JSON.stringify({
                requestType: MM_REQUESTS.MY_STATUS,
            }),
        )
    }),

    sendAIChallenge: action((state, config) => {
        if (state.socket === undefined) return

        const challenge: AIChallengeCreate = {
            requestType: MM_REQUESTS.CHALLENGE_CREATE,
            rawData: {
                challengerColor: config.color,
                timeMode: {
                    durationMinutes: config.time,
                    clockIncrementSeconds: config.increment ? config.increment : 0,
                },
                matchKind: 'human_vs_ai',
                challengeeId: config.challengeeId,
                difficulty: {
                    level: config.strength,
                },
            },
        }
        if (config.fen) challenge.rawData.FEN = config.fen

        config.navigate('/')

        state.socket.send(JSON.stringify(challenge))
    }),

    acceptChallenge: action((state, payload) => {
        if (state.socket === undefined) return
        state.socket.send(
            JSON.stringify({
                requestType: MM_REQUESTS.CHALLENGE_ACCEPT,
                rawData: {
                    [payload.challengeType === MMChallengeType.CUSTOM ? 'customId' : 'pairId']: payload.id,
                },
            }),
        )
    }),

    sendPlayerChallenge: action((state, config) => {
        if (state.socket === undefined) return

        state.socket.send(
            JSON.stringify({
                requestType: MM_REQUESTS.CHALLENGE_CREATE,
                rawData: {
                    challengerColor: config.color,
                    timeMode: {
                        durationMinutes: config.time,
                        clockIncrementSeconds: config.increment ? config.increment : 0,
                    },
                    matchKind: 'human_vs_human',
                    rated: config.rated,
                    challengeeId: config.challengeeId,
                    challengeType: config.challengeType,
                    ratingFrom: config.ratingFrom,
                    ratingTo: config.ratingTo,
                },
            }),
        )
    }),

    sendRematchChallenge: action((state, config) => {
        if (state.socket === undefined) return
        state.socket.send(
            JSON.stringify({
                requestType: MM_REQUESTS.CHALLENGE_REMATCH,
                rawData: {
                    pairId: config.matchId,
                },
            }),
        )
    }),

    sendDeleteChallenge: action((state, challengeId) => {
        if (state.socket === undefined) return
        state.socket.send(
            JSON.stringify({
                requestType: MM_REQUESTS.CHALLENGE_DELETE,
                rawData: {
                    challengeId: challengeId,
                },
            }),
        )
    }),

    sendRejectChallenge: action((state, payload) => {
        if (state.socket === undefined) return
        state.socket.send(
            JSON.stringify({
                requestType: MM_REQUESTS.CHALLENGE_REJECT,
                rawData: {
                    pairId: payload.pairId,
                    isRematch: payload.isRematch,
                },
            }),
        )
    }),

    getScore: action((state, data) => {
        if (state.socket === undefined) return
        state.socket.send(
            JSON.stringify({
                requestType: MM_REQUESTS.CHALLENGE_SCORE,
                rawData: {
                    challengerId: data.challengerId,
                    challengeeId: data.challengeeId,
                },
            }),
        )
    }),

    showGameOverDialog: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
        try {
            const { token, gameView } = getStoreState()
            if (gameView.gameState === GameViewState.GAME_RUNNING) {
                const response = await csAPI.get(`/api/history${createQueryParams({ lastRecord: true, token })}`)
                if (response.data) {
                    const d = response.data.matchHistory[0]
                    if (!!d) {
                        const storeActions = getStoreActions()
                        storeActions.gameView.setOpponentDisconnected(undefined)
                        storeActions.setDrawerOpen('closed')
                        storeActions.gameView.setGameEndEnabled(true)
                        storeActions.gameView.setCanClaimVictory(false)

                        const result: GameResult = {
                            winner: undefined,
                            type: GameResultType.Unknown,
                            newRating: {
                                player1_id: d.player1.id,
                                user1_rating: d.player1.finalRating,
                                player2_id: d.player2.id,
                                user2_rating: d.player2.finalRating,
                            },
                            outcome: d.outcome,
                            botdRewardURL: d.botdRewardURL,
                        }

                        // check the result who won ("0-1" is a fixed notation in chess!)
                        if (d.outcome === '1-0') result.winner = Color.White
                        else if (d.outcome === '0-1') result.winner = Color.Black

                        // decipher the reason
                        result.type = getGameResultType(d.reason)

                        // save game end result
                        storeActions.gameView.setGameResult(result)

                        // update clock
                        // const now = new Date().getTime()
                        // const updateForClock = { secondsRemaining: 0, when: now }
                        // if (result.winner === Color.White) {
                        //     storeActions.gameView.setClockInstantBlack(updateForClock)
                        // } else {
                        //     storeActions.gameView.setClockInstantWhite(updateForClock)
                        // }

                        // clear match data
                        storeActions.gameView.setMatchData({
                            matchId: '',
                            gameServiceUrl: '',
                        })

                        // send game result to analytics
                        const timeInGame = gameView.gameStartedTimestamp
                            ? Date.now() - gameView.gameStartedTimestamp
                            : 0
                        analyticsManager.dispatchEvent('gameFinished', {
                            timeInGame: timeInGame / (60 * 1000),
                            reason: d.reason,
                            method: d.method,
                        })
                    }
                }
            }
        } catch (error) {
            console.error('Get last game from history error:', error)
        }
    }),

    reset: action((state) => {
        state.challengesToMe = []
        state.autoChallengesByMe = []
        state.customChallengesByMe = []
        state.openChallenges = []
        state.directChallengesByMe = []
        state.rematchMatchData = {
            pairId: '',
            userName: '',
        }
        state.socket = undefined
    }),
}

export default matchMakingConnectionModel
