import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { gameFromPGN, pgnParseHeaders } from '../../chess/pgn'
import { useConfig } from '../../sharedComponents/src/globalHeader/services/AppConfigService'
import { useStoreActions } from '../../store/hooks'
import { ProfilePages } from '../Profile/constants'

enum DeepLinkTypes {
    SYSTEM = 'system',
    DRAWER = 'drawer',
    PGN = 'pgn',
    VIEW = 'view',
}

enum DrawerTypes {
    GAME = 'game',
    PLAYERS = 'players',
    BOTS = 'bots',
}

enum ViewTypes {
    DASHBOARD = 'dashboard',
    ACCOUNT = 'account',
}

const deepLinkTypesValues = Object.values(DeepLinkTypes)

const DeepLinksService: React.FC = () => {
    const setDrawerOpen = useStoreActions((state) => state.setDrawerOpen)
    const setActiveTab = useStoreActions((state) => state.setActiveTab)
    const setPGNHeader = useStoreActions((state) => state.setPGNHeader)
    const setAnalysisGameTree = useStoreActions((state) => state.analysisMode.setGameTree)
    const setProfileMenu = useStoreActions((state) => state.profileView.setProfileMenu)

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        let searchParams = ''
        if (location.search == '' && location.hash !== '') {
            searchParams = location.hash.replace('#/', '')
        } else {
            searchParams = location.search
        }
        const params = new URLSearchParams(searchParams)

        deepLinkTypesValues.map((type) => {
            const paramsData = params.getAll(type)
            const param = paramsData[0]
            if (param) {
                switch (type) {
                    case DeepLinkTypes.SYSTEM:
                        useConfig(param, import.meta.env.VITE_REACT_APP_AMS_URL)
                        break
                    case DeepLinkTypes.DRAWER:
                        redirectToDrawer(param.toLowerCase() as DrawerTypes)
                        break
                    case DeepLinkTypes.PGN:
                        redirectToAnalysisWithPGN(param)
                        break
                    case DeepLinkTypes.VIEW:
                        redirectToView(param.toLowerCase() as ViewTypes)
                        break
                    default:
                        break
                }
            }
        })
    }, [])

    const redirectToView = (param: ViewTypes) => {
        switch (param) {
            case ViewTypes.ACCOUNT:
                setProfileMenu(ProfilePages.ACCOUNT)
                navigate('/profile')
                break
            case ViewTypes.DASHBOARD:
                setProfileMenu(ProfilePages.DASHBOARD)
                navigate('/profile')
                break
            default:
                break
        }
    }

    const redirectToDrawer = (param: DrawerTypes) => {
        switch (param) {
            case DrawerTypes.PLAYERS:
                setActiveTab(1)
                break
            case DrawerTypes.BOTS:
                setActiveTab(2)
                break
            case DrawerTypes.GAME:
            default:
                setActiveTab(0)
                break
        }
        setDrawerOpen('open')
        navigate('/')
    }

    const redirectToAnalysisWithPGN = (pgn: string) => {
        const decodedPGN = decodeURIComponent(pgn)
        let headers = pgnParseHeaders(decodedPGN)
        if (headers.length > 0) {
            setPGNHeader({
                Event: '-',
                Site: '-',
                Date: '',
                Round: '-',
                White: '',
                Black: '',
                Result: '*',
                ...headers[0],
            })
            let result = gameFromPGN(decodedPGN)
            setAnalysisGameTree(result)
            navigate('/analysis')
        }
    }

    return null
}

DeepLinksService.displayName = 'DeepLinksService'

export default DeepLinksService
