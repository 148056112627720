import { Action, action } from 'easy-peasy'
import {
    addMoveToGTPosition,
    createRootGameTree,
    getMoveById,
    getNextPosition,
    getPreviousPosition,
    getStartingPosition,
} from '../../chess/gameTree'
import { BasePositionFEN } from '../../chess/positionPresets'
import { Color, GameTree, Move } from '../../chess/types'
import { GameData, GameResultType } from '../../store/types'

export type GameAnalysisModel = {
    flipped: boolean
    deleteModeOn: boolean
    evalBarVisibility: boolean
    engineVisibility: boolean
    gameTree: GameTree
    currentPositionId: string
    myColor: Color
    colorToMove: number
    fen: string
    playComputerFen: string
    resultType?: GameResultType
    setGameTree: Action<GameAnalysisModel, GameTree>
    setCurrentPositionId: Action<GameAnalysisModel, string>
    setFlipBoard: Action<GameAnalysisModel, boolean>
    setEvalBarVisibility: Action<GameAnalysisModel, boolean>
    setEngineVisibility: Action<GameAnalysisModel, boolean>
    setDeleteModeOn: Action<GameAnalysisModel, boolean>
    initialiseGame: Action<GameAnalysisModel, { gameData: GameData; userID: string }>
    addVariationMove: Action<GameAnalysisModel, Move>
    setGameTreeWithFen: Action<GameAnalysisModel, string>
    setMoveAnnotation: Action<GameAnalysisModel, { moveID: string; annotation: string }>
    setColorToMove: Action<GameAnalysisModel, number>
    setFen: Action<GameAnalysisModel, string>
    setPlayComputerFen: Action<GameAnalysisModel, string>
    setToPreviousPosition: Action<GameAnalysisModel>
    setToNextPosition: Action<GameAnalysisModel>
    setResultType: Action<GameAnalysisModel, GameResultType | undefined>
}

const initialGameTree = createRootGameTree(BasePositionFEN)

const AnalysisViewModel: GameAnalysisModel = {
    myColor: Color.White,
    flipped: false,
    evalBarVisibility: false,
    engineVisibility: false,
    deleteModeOn: false,
    gameTree: initialGameTree,
    currentPositionId: getStartingPosition(initialGameTree).id,
    colorToMove: 1,
    fen: '',
    playComputerFen: BasePositionFEN,
    resultType: undefined,

    // ----------------------------------------------------------------------------- actions
    setGameTree: action((state, payload) => {
        state.gameTree = payload
        state.currentPositionId = getStartingPosition(payload).id
    }),

    setCurrentPositionId: action((state, payload) => {
        state.currentPositionId = payload
    }),

    setFlipBoard: action((state, flipped) => {
        state.flipped = flipped
    }),

    setEvalBarVisibility: action((state, data) => {
        state.evalBarVisibility = data
    }),

    setEngineVisibility: action((state, data) => {
        state.engineVisibility = data
    }),

    setFen: action((state, fen) => {
        state.fen = fen
    }),

    setDeleteModeOn: action((state, deleteModeOn) => {
        state.deleteModeOn = deleteModeOn
    }),

    setPlayComputerFen: action((state, fen) => {
        state.playComputerFen = fen
    }),

    addVariationMove: action((state, newMove) => {
        if (state.gameTree && state.currentPositionId) {
            let position = state.gameTree.positions.find((p) => p.id === state.currentPositionId)
            if (!!position) {
                const GameTreePosition = addMoveToGTPosition(state.gameTree, position, newMove)
                state.currentPositionId = GameTreePosition.id
                state.myColor = GameTreePosition.position.turn === Color.White ? Color.Black : Color.White
            }
        }
    }),

    initialiseGame: action((state, gd) => {
        const newRoot = createRootGameTree(gd.gameData.startingFen || BasePositionFEN)
        state.gameTree = newRoot
        state.currentPositionId = getStartingPosition(newRoot).id
        let myColor = Color.White
        if (gd.gameData.player2.playerId === gd.userID) {
            myColor = Color.Black
            state.flipped = true
        }
        state.myColor = myColor
    }),

    setGameTreeWithFen: action((state, fen) => {
        const newRoot = createRootGameTree(fen)
        state.gameTree = newRoot
        state.currentPositionId = getStartingPosition(newRoot).id
    }),

    setMoveAnnotation: action((state, { moveID, annotation }) => {
        if (!state.gameTree) return
        const m = getMoveById(state.gameTree, moveID)
        if (m) m.annotation = annotation
    }),
    setColorToMove: action((state, number) => {
        state.colorToMove = number
    }),

    setToPreviousPosition: action((state) => {
        if (!state.gameTree || !state.currentPositionId) return
        const prevPosition = getPreviousPosition(state.gameTree, state.currentPositionId)
        if (prevPosition) state.currentPositionId = prevPosition.id
    }),

    setToNextPosition: action((state) => {
        if (!state.gameTree || !state.currentPositionId) return
        const nextPosition = getNextPosition(state.gameTree, state.currentPositionId)
        if (nextPosition) state.currentPositionId = nextPosition.id
    }),

    setResultType: action((state, resultType) => {
        state.resultType = resultType
    }),
}

export default AnalysisViewModel
