import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    SxProps,
    TextField,
    Typography,
} from '@mui/material'
import { useCallback, useState } from 'react'
import { getMoveById, getPositionById } from '../../chess/gameTree'
import { Color, GameTree, GameTreeMove } from '../../chess/types'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { GameResult, GameResultType } from '../../store/types'
import CondensedNotation from './CondensedNotation'
import NotationTable from './NotationTable'

export type NotationProps = {
    gameTree: GameTree
    gameResult?: GameResult | undefined
    currentPositionId: string
    matchTitle: string
    onAnnotationChange?: (moveId: string, annotation: string) => void
    editableAnnotations: boolean
    rated?: boolean
    analysis?: boolean
    vsComputer?: boolean
    showBotLevel?: boolean
    customOnMoveSelected?: (id: string) => void
    vertical?: boolean
    analysisHeaders?: [{ [index: string]: string }, number]
    opening?: string
    children?: React.ReactNode
    isTable?: boolean
    sx?: SxProps
}

export function Notation(props: NotationProps) {
    let {
        gameTree,
        currentPositionId,
        gameResult,
        matchTitle,
        editableAnnotations,
        onAnnotationChange,
        rated,
        customOnMoveSelected,
        analysis,
        vsComputer,
        showBotLevel = false,
        analysisHeaders,
        vertical,
        opening,
        children,
        isTable = true,
        sx,
    } = props
    const engineDifficulty = useStoreState((state) => state.gameView.engineDifficulty)
    const timeMode = useStoreState((state) => state.gameView.gameData?.timeMode)
    const resultType = useStoreState((state) => state.analysisMode.resultType)
    const setGameViewCurrentPositionId = useStoreActions((state) => state.gameView.setCurrentPositionId)
    const setAnalysisCurrentPositionId = useStoreActions((state) => state.analysisMode.setCurrentPositionId)
    const setCurrentPositionId = analysis ? setAnalysisCurrentPositionId : setGameViewCurrentPositionId

    let activeMove: GameTreeMove | undefined = undefined

    const [open, setOpen] = useState(false)
    const [annotation, setAnnotation] = useState<string>('')
    const [editMoveID, setEditMoveID] = useState<string>('')

    const handleClickOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const onMoveSelected = useCallback(
        (id: string) => {
            if (!gameTree) return
            const m = getMoveById(gameTree, id)
            if (!m) return
            if (customOnMoveSelected) {
                customOnMoveSelected(id)
            } else {
                setCurrentPositionId(m.nextPositionId)
            }
        },
        [gameTree, setCurrentPositionId, customOnMoveSelected],
    )

    // useEffect(() => {
    //     if (analysis) {
    //         setTable(false)
    //     }
    // }, [analysis])

    let tp = getPositionById(gameTree, currentPositionId)
    if (tp && tp.previousMoveId !== undefined) {
        activeMove = getMoveById(gameTree, tp!.previousMoveId)
    }

    let result = ''
    if (gameResult) {
        if (gameResult.winner === Color.White) result = '1-0'
        else if (gameResult.winner === Color.Black) result = '0-1'
        else if (gameResult.type === GameResultType.Aborted) result = '*'
        else result = '1/2 - 1/2'
    } else if (analysisHeaders) {
        result = analysisHeaders[0]['Result']
    }

    return (
        <>
            <Box
                sx={{ border: '3px solid', backgroundColor: 'background.paper', ...sx }}
                display="flex"
                flexDirection="column"
                flexGrow="1"
                height={{ xs: '258px', md: 'unset' }}
                minHeight="158px"
                position="relative"
                padding={0.5}
            >
                {children}
                <Paper
                    sx={{
                        px: '8px',
                        justifyContent: 'space-between',
                    }}
                    square
                >
                    <Typography variant="subtitle1" color="text.disabled">
                        {matchTitle !== '-' && matchTitle}
                        {rated === undefined && ''}
                        {rated === true && ' Rated'}
                        {rated === false && !vsComputer && ' Unrated'}
                        {vsComputer === true &&
                            engineDifficulty &&
                            showBotLevel &&
                            ` (Strength ${engineDifficulty.level})`}
                        {analysisHeaders && analysisHeaders[0]['Date'] !== '-' && ' ' + analysisHeaders[0]['Date']}
                    </Typography>
                    {analysis && analysisHeaders && analysisHeaders[0]['Opening'] !== '-' && (
                        <Typography color="secondary" variant="subtitle1">
                            {analysisHeaders[0]['Opening']}
                        </Typography>
                    )}

                    {!analysis && opening && (
                        <Typography
                            whiteSpace="none"
                            color="secondary"
                            variant="subtitle1"
                            width="auto"
                            sx={{ whiteSpace: 'nowrap' }}
                        >
                            {opening}
                        </Typography>
                    )}
                </Paper>
                {isTable ? (
                    <NotationTable
                        gameTree={gameTree}
                        currentPositionId={currentPositionId}
                        selectMove={onMoveSelected}
                        handleClickOpen={handleClickOpen}
                        setAnnotation={setAnnotation}
                        setEditMoveID={setEditMoveID}
                        editableAnnotations={editableAnnotations}
                        result={result}
                    />
                ) : (
                    <CondensedNotation
                        gameTree={gameTree}
                        timeMode={timeMode}
                        currentPositionId={currentPositionId}
                        selectMove={onMoveSelected}
                        handleClickOpen={handleClickOpen}
                        setAnnotation={setAnnotation}
                        setEditMoveID={setEditMoveID}
                        editableAnnotations={editableAnnotations}
                        result={result}
                        resultType={!!gameResult ? gameResult?.type : resultType}
                    />
                )}
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Annotation Edit</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Here you can edit the annotation for move {activeMove?.displayString}
                    </DialogContentText>
                    <TextField
                        id="annotation"
                        fullWidth
                        multiline
                        variant="standard"
                        value={annotation}
                        onChange={(e) => setAnnotation(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            if (!onAnnotationChange) return
                            onAnnotationChange(editMoveID, annotation!)
                            handleClose()
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
