import CloseIcon from '@mui/icons-material/Close'
import { alpha, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from '../../store/hooks'

export const PopupService = () => {
    const navigate = useNavigate()
    const { shareLocation, removePopups } = useStoreActions((actions) => actions.popupService)
    const { popups } = useStoreState((state) => state.popupService)
    const [popup] = popups

    const { isConnected } = useStoreState((state) => state.ws)
    const { pathname } = useLocation()

    useEffect(() => {
        if (isConnected) {
            shareLocation(pathname)
        }
    }, [isConnected, pathname])

    const handleClose = () => {
        removePopups()
    }

    const handleCTA = (url) => {
        handleClose()
        if (url) {
            if (url.includes(window.location.origin)) {
                const parsedUrl = url.replace(window.location.origin, '')
                navigate(parsedUrl)
            } else {
                window.location.href = url
            }
        }
    }

    if (!popup) return null

    return (
        <Dialog
            open={Boolean(popup)}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                zIndex: 9999,
                '.MuiDialog-paper': {
                    flexDirection: 'column',
                    // maxHeight: 'calc(100% - 64px)'
                    maxWidth: '680px',
                    width: '100%',
                    margin: '1rem',
                },
                '.MuiPaper-root': {
                    backgroundColor: popup.bgColor,
                    p: 4,
                },
            }}
        >
            <DialogTitle>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 12,
                        right: 12,
                        ...(popup.textColor
                            ? {
                                  color: `${popup.textColor} !important`,
                                  '&:hover': {
                                      backgroundColor: `${alpha(popup.textColor, 0.3)} !important`,
                                  },
                              }
                            : {}),
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <img height="100%" width="100%" src={`${popup.asset}`} />

                {/* <DialogContentText></DialogContentText> */}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                    sx={
                        popup.textColor
                            ? {
                                  borderColor: `${popup.textColor} !important`,
                                  color: `${popup.textColor} !important`,
                                  '&:hover': {
                                      backgroundColor: `${alpha(popup.textColor, 0.3)} !important`,
                                  },
                              }
                            : {}
                    }
                    variant="outlined"
                    onClick={() => handleCTA(popup.ctaLink)}
                >
                    {popup.ctaText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
