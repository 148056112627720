import { Stack, Typography } from '@mui/material'
import { SUPPORT_LINK } from '../../utils/links'
import { AreaType, UserData } from '../GlobalHeader'
import LogoutIcon from '../icons/logout'
import ProfileIcon from '../icons/profile'
import SettingsIcon from '../icons/settings'
import SupportIcon from '../icons/support'
import Dropdown, { DropdownMenuItem } from './Dropdown'

export interface UserMenuProps {
    onLogout: () => void
    userName: string
    playAreaURL: string
    avatarUrl?: string
    isGuest?: boolean
    area: AreaType
    Link: any
    onLogIn: () => void
    onSignUp: () => void
    onSettings: () => void
    userData: UserData
}

const UserMenu = (props: UserMenuProps) => {
    const { onLogout, userName, avatarUrl, playAreaURL, isGuest, area, Link, onLogIn, onSignUp, onSettings, userData } =
        props

    const userNavigation: DropdownMenuItem[] = [
        { title: 'Profile', icon: <ProfileIcon />, link: `${playAreaURL}/profile` },
        { title: 'Settings', icon: <SettingsIcon />, onClick: onSettings },
        { title: 'Help', icon: <SupportIcon />, link: SUPPORT_LINK, target: '_blank' },
        ...(isGuest
            ? [
                  { title: 'Log in', onClick: onLogIn },
                  { title: 'Sign up', onClick: onSignUp },
              ]
            : []),
        ...(!isGuest ? [{ title: 'Log out', icon: <LogoutIcon />, onClick: () => onLogout() }] : []),
    ]

    return (
        <Stack
            direction="row"
            sx={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Dropdown
                dropdownItems={userNavigation}
                avatarUrl={avatarUrl}
                reward={userData.reward}
                area={area}
                Link={Link}
                menuButtonTitle={
                    <Typography
                        variant="body1"
                        textTransform="none"
                        sx={{
                            '@media screen and (max-width: 600px)': {
                                display: 'none',
                            },
                        }}
                    >
                        {userName}
                    </Typography>
                }
            />
        </Stack>
    )
}

export default UserMenu
