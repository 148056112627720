import { Action, action, thunk, Thunk } from 'easy-peasy'
import { StoreModel } from '../../store/store'
import getGameHistory, { GameHistoryData } from './API'
import { GameHistoryColumns, GameHistoryTypes, ProfilePages, SortDirection } from './constants'

type RowSettings = {
    key: string
    value: string
    hideRating?: boolean
    checkProvisional?: boolean
    color: string
}

type StatsItem = {
    name: string
    color: string
    blitz: number
    bullet: number
    classic: number
    rapid: number
    blitzInfo?: string
    bulletInfo?: string
    classicInfo?: string
    rapidInfo?: string
    hideRating?: boolean
    blitzIsProvisional?: boolean
    bulletIsProvisional?: boolean
    classicIsProvisional?: boolean
    rapidIsProvisional?: boolean
}

type HistoryItem = {
    game: string
    date: string
    white: string
    black: string
    opponent: string
    result: string
    color: string
}

type GameHistorySort = {
    order: SortDirection
    orderBy: GameHistoryColumns
}

export type ProfileModel = {
    profileMenu: ProfilePages
    selectedContainer: string
    stats: Array<StatsItem>
    gameHistory: Array<HistoryItem>
    recordAmount: number
    historyLoading: boolean
    filter: GameHistoryTypes
    gamesPerPage: number
    sort: GameHistorySort
    activePage: number
    newAvatar: File | null
    currentAvatar: string
    setProfileMenu: Action<ProfileModel, ProfilePages>
    setSelectedContainer: Action<ProfileModel, string>
    setStats: Action<ProfileModel, Array<StatsItem>>
    initStats: Thunk<ProfileModel, undefined, {}, StoreModel>
    setRecordAmount: Action<ProfileModel, number>
    setHistoryLoading: Action<ProfileModel, boolean>
    setFilter: Action<ProfileModel, GameHistoryTypes>
    setGamesPerPage: Action<ProfileModel, number>
    setSort: Action<ProfileModel, GameHistorySort>
    setActivePage: Action<ProfileModel, number>
    setGameHistory: Action<ProfileModel, Array<HistoryItem>>
    getGameHistory: Thunk<ProfileModel, undefined, {}, StoreModel>
    setNewAvatar: Action<ProfileModel, File | null>
    setCurrentAvatar: Action<ProfileModel, string>
}

const ProfileViewModel: ProfileModel = {
    profileMenu: ProfilePages.DASHBOARD,
    selectedContainer: '',
    stats: [],
    gameHistory: [],
    recordAmount: 0,
    historyLoading: true,
    filter: GameHistoryTypes.all,
    sort: {
        order: SortDirection.desc,
        orderBy: GameHistoryColumns.date,
    },
    gamesPerPage: 25,
    activePage: 0,
    newAvatar: null,
    currentAvatar: '',

    // ----------------------------------------------------------------------------- actions
    setProfileMenu: action((state, payload) => {
        state.profileMenu = payload
    }),

    setSelectedContainer: action((state, payload) => {
        state.selectedContainer = payload
    }),

    setStats: action((state, payload) => {
        state.stats = payload
    }),

    initStats: thunk(async (actions, payload, { getStoreState }) => {
        const { gameView, userData } = getStoreState()
        const settings = gameView.settings
        const ratings = userData.userData?.ratings

        const defaultRows: Array<RowSettings> = [
            {
                key: 'rating',
                value: 'Rating',
                color: 'text.primary',
                hideRating: settings.friendlyMode,
                checkProvisional: true,
            },
            { key: 'total_games', value: 'Total', color: 'text.primary' },
            { key: 'win', value: 'Wins', color: 'success.dark' },
            { key: 'draw', value: 'Draw', color: 'text.primary' },
            { key: 'loss', value: 'Losses', color: 'error.dark' },
            {
                key: 'best',
                value: 'Best',
                color: 'text.primary',
                hideRating: settings.friendlyMode,
                checkProvisional: true,
            },
        ]

        const data = defaultRows.reduce((rowsAcc: Array<StatsItem>, row) => {
            if (ratings) {
                const ratingsKeys = Object.keys(ratings)
                const newRow: any = {
                    name: row.value,
                    color: row.color,
                }
                if (row.hideRating !== undefined) {
                    newRow.hideRating = row.hideRating
                }
                ratingsKeys.map((key) => {
                    const rating = ratings[key as keyof typeof ratings]
                    newRow[key] = rating[row.key as keyof typeof rating] || 0
                    const itemInfo = rating[`${row.key}_date` as keyof typeof rating]
                    if (itemInfo) {
                        newRow[`${key}Info`] = itemInfo
                    }
                    if (row.checkProvisional) {
                        newRow[`${key}IsProvisional`] = rating.is_provisional
                    }
                })
                return [...rowsAcc, newRow]
            }

            return rowsAcc
        }, [])

        actions.setStats(data)
    }),

    setRecordAmount: action((state, payload) => {
        state.recordAmount = payload
    }),

    setHistoryLoading: action((state, payload) => {
        state.historyLoading = payload
    }),

    setFilter: action((state, payload) => {
        state.filter = payload
    }),

    setGamesPerPage: action((state, payload) => {
        state.gamesPerPage = payload
        state.activePage = 0
    }),

    setSort: action((state, payload) => {
        state.sort = payload
    }),

    setActivePage: action((state, payload) => {
        state.activePage = payload
    }),

    setGameHistory: action((state, payload) => {
        state.gameHistory = payload
        state.historyLoading = false
    }),

    getGameHistory: thunk(async (actions, payload, { getState, getStoreState }) => {
        const { filter, gamesPerPage, sort, activePage } = getState()
        const { userData } = getStoreState()
        actions.setHistoryLoading(true)
        if (userData.token) {
            const data: GameHistoryData = await getGameHistory(
                userData.token,
                filter,
                gamesPerPage,
                activePage * gamesPerPage,
                `${sort.orderBy}=${sort.order}`,
            )
            if (!data.error) {
                const history =
                    data?.matchHistory?.reduce((acc: Array<HistoryItem>, match) => {
                        const player = match.player1.id === userData.userData?.id ? match.player1 : match.player2
                        const opponent = match.player1.id === userData.userData?.id ? match.player2 : match.player1
                        const item: HistoryItem = {
                            game: `${match.timeMode.durationMinutes}+${match.timeMode.clockIncrementSeconds}`,
                            date: new Date(match.startedAt).toLocaleDateString('en-US'),
                            white: player.color === 'white' ? player.userName : opponent.userName,
                            black: player.color === 'black' ? player.userName : opponent.userName,
                            result: match.outcome,
                            opponent: opponent.userName,
                            color: player.color,
                        }
                        return [...acc, item]
                    }, []) || []
                actions.setGameHistory(history)
                actions.setRecordAmount(data.recordAmount)
            } else {
                actions.setGameHistory([])
                actions.setRecordAmount(0)
            }
        } else {
            actions.setGameHistory([])
            actions.setRecordAmount(0)
        }
        setTimeout(() => {
            actions.setHistoryLoading(false)
        }, 200)
    }),

    setNewAvatar: action((state, payload) => {
        state.newAvatar = payload
    }),

    setCurrentAvatar: action((state, payload) => {
        state.currentAvatar = payload
    }),
}

export default ProfileViewModel
