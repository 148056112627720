import { GameData, LiveGameData, RatingsObject } from '../store/types'

export type PlayerInfo = {
    name: string
    avatarUrl?: string
    nationality?: string
    rating?: number
    title?: string
    userName?: string
    human?: boolean
    playerId?: string
    ratings?: RatingsObject | null
    ratingType?: string
    showScore?: boolean
    reward?: {
        tier: number
        season: string
    }
    rewards: Array<{ name: string, url: string }>
}

export type PlayerReward = {
    playerID: string,
    rewardURL: string,
    rewardName: string
}

export const getOpponentInfos = (
    md: LiveGameData | undefined,
): { playerWhite: PlayerInfo; playerBlack: PlayerInfo } => {
    const playerWhite: PlayerInfo = { name: '', rewards: [] }
    const playerBlack: PlayerInfo = { name: '', rewards: [] }

    if (!!md) {
        const whiteUser = md.player1.playerColor === 'white' ? md.player1 : md.player2
        const blackUser = md.player1.playerColor === 'black' ? md.player1 : md.player2

        playerWhite.rating = whiteUser.ratings ? whiteUser.ratings[md.timeMode.name.toLowerCase()].rating : null
        playerWhite.nationality = whiteUser.countryId
        playerWhite.playerId = whiteUser.playerId
        playerWhite.name = whiteUser.userName
        playerWhite.avatarUrl = whiteUser.avatarUrl
        playerWhite.ratings = whiteUser.ratings
        playerWhite.ratingType = md.timeMode.name
        playerWhite.showScore = false
        playerWhite.title = whiteUser.title
        playerWhite.reward = whiteUser.reward
        playerWhite.human = whiteUser.playerKind === 'human'

        playerBlack.rating = blackUser.ratings ? blackUser.ratings[md.timeMode.name.toLowerCase()].rating : null
        playerBlack.nationality = blackUser.countryId
        playerBlack.playerId = blackUser.playerId
        playerBlack.name = blackUser.userName
        playerBlack.avatarUrl = blackUser.avatarUrl
        playerBlack.ratings = blackUser.ratings
        playerBlack.ratingType = md.timeMode.name
        playerBlack.showScore = false
        playerBlack.title = blackUser.title
        playerBlack.reward = blackUser.reward
        playerBlack.human = blackUser.playerKind === 'human'
    }

    return { playerWhite, playerBlack }
}

export const getPlayerInfos = (md: GameData | undefined, rewards: Array<PlayerReward>): { playerWhite: PlayerInfo; playerBlack: PlayerInfo } => {
    const playerWhite: PlayerInfo = { name: '', rewards: [] }
    const playerBlack: PlayerInfo = { name: '', rewards: [] }

    if (!!md) {
        const whiteUser = md.player1.playerColor === 'white' ? md.player1 : md.player2
        const blackUser = md.player1.playerColor === 'black' ? md.player1 : md.player2

        playerWhite.name = whiteUser.userName
        playerBlack.name = blackUser.userName
        playerWhite.avatarUrl = whiteUser.avatarUrl
        playerBlack.avatarUrl = blackUser.avatarUrl
        playerWhite.human = whiteUser.playerKind === 'human'
        playerBlack.human = blackUser.playerKind === 'human'
        playerWhite.rating = whiteUser.ratings ? whiteUser.ratings[md.timeMode.name.toLowerCase()].rating : null
        playerBlack.rating = blackUser.ratings ? blackUser.ratings[md.timeMode.name.toLowerCase()].rating : null
        playerWhite.ratings = whiteUser.ratings
        playerBlack.ratings = blackUser.ratings
        playerWhite.ratingType = md.timeMode.name
        playerBlack.ratingType = md.timeMode.name
        playerWhite.showScore = whiteUser.playerKind === 'human' && blackUser.playerKind === 'human'
        playerBlack.showScore = whiteUser.playerKind === 'human' && blackUser.playerKind === 'human'
        playerBlack.reward = blackUser.reward
        playerWhite.reward = whiteUser.reward

        if (whiteUser.playerKind === 'human') {
            playerWhite.nationality = whiteUser.countryId
            playerWhite.playerId = whiteUser.playerId
            playerWhite.title = whiteUser.title
        }
        if (blackUser.playerKind === 'human') {
            playerBlack.nationality = blackUser.countryId
            playerBlack.playerId = blackUser.playerId
            playerBlack.title = blackUser.title
        }

        // TODO: This is a temporary solution, we should get the rewards from the backend
        rewards.map((reward) => {
            if (reward.playerID === whiteUser.playerId) {
                playerWhite.rewards.push({ name: reward.rewardName, url: reward.rewardURL })
            }
            if (reward.playerID === blackUser.playerId) {
                playerBlack.rewards.push({ name: reward.rewardName, url: reward.rewardURL })
            }
        })
    }

    return { playerWhite, playerBlack }
}
