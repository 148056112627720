import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import isEmpty from 'lodash-es/isEmpty'
import React, { useEffect, useState } from 'react'
import { countries } from '../../../sharedComponents/src/globalHeader/common/countries'
import { FramedAvatar } from '../../../sharedComponents/src/globalHeader/components/FramedAvatar'
import { useStoreState } from '../../../store/hooks'

const ProfileBlock: React.FC = () => {
    const user = useStoreState((state) => state.userData.userData)

    const [userCountryName, setUserCountryName] = useState<string>()

    const tags: Array<string> = !isEmpty(user?.title) ? user?.title?.split(',') || [] : []

    useEffect(() => {
        const userCountry = countries.find((country) => country.code === user?.country)
        setUserCountryName(userCountry?.name || '')
    }, [user])

    return (
        <Stack gap={3} sx={{ p: 2, alignItems: 'center' }}>
            {/* User avatar */}
            <FramedAvatar
                avatar={user?.avatar_url || ''}
                season={user?.reward.season}
                reward={user?.reward.tier}
                sx={{
                    borderRadius: '0',
                    m: 4,
                    mb: 0,
                    color: 'background.default',
                    height: {
                        xs: '12.3rem',
                        lg: '15.5rem',
                    },
                    width: {
                        xs: '12.3rem',
                        lg: '15.5rem',
                    },
                }}
            />

            {/* User first and last name */}
            {user?.first_name || user?.last_name ? (
                <Typography color="#6B7280">{`${user.first_name} ${user.last_name}`}</Typography>
            ) : null}

            {/* User name */}
            <Typography fontWeight={700} variant="h2" fontFamily="Space Grotesk" textTransform="none">
                {user?.user_name}
            </Typography>

            {/* User country */}
            {userCountryName !== '' && (
                <Stack direction="row" gap={1}>
                    <img src={'/assets/images/icons/vector.svg'} />
                    <Typography color="#6B7280">{userCountryName}</Typography>
                </Stack>
            )}

            {/* User tags */}
            {tags.length > 0 &&
                tags.map((tag, index) => (
                    <Stack
                        sx={{
                            px: 0.5,
                            m: 0.5,
                            border: '1px solid',
                            borderColor: 'text.primary',
                            borderRadius: 2,
                        }}
                        key={index}
                    >
                        {tag.toUpperCase()}
                    </Stack>
                ))}
        </Stack>
    )
}

ProfileBlock.displayName = 'ProfileBlock'

export default ProfileBlock
