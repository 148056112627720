import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import FollowIcon from '../assets/icons/follow.svg?react'
import UnfollowIcon from '../assets/icons/unfollow.svg?react'
import { Color, GameTreePosition } from '../chess/types'
import { GameViewState } from '../features/gameView/GameViewModel'
import { MaterialBalance } from '../features/gameView/components/materialBalance/MaterialBalance'
import { PlayerInfo } from '../functions/getPlayerInfos'
import { IOverallRecord } from '../functions/getPlayersStats'
import { imgFallback } from '../helpers/imgFallback'
import { Rules } from '../sharedComponents/src/globalHeader/GlobalHeader'
import { FramedAvatar } from '../sharedComponents/src/globalHeader/components/FramedAvatar'
import { followUser, unfollowUser } from '../sharedComponents/src/globalHeader/functions/ams-functions'
import { Layout, useResponsiveSizings } from '../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../store/hooks'
import { RatingsObject } from '../store/types'
import ProfileInfo from './ProfileInfo/ProfileInfo'
import Rating from './Rating/Rating'
import { UserTitle } from './userTitle/UserTitle'

const avatarStyle = {
    height: '60px',
    width: '60px',
}

export enum PageView {
    GAME = 'game',
    OBSERVE = 'observe',
    ANALYSIS = 'analysis',
}

interface PlayerInfoBarProps {
    player: PlayerInfo | undefined
    gameState: GameViewState | undefined
    gtpos: GameTreePosition | undefined
    clock: JSX.Element | undefined
    color: Color
    view: PageView
    latency?: number
    top: boolean
    overallRecord?: IOverallRecord
    totalScore?: number
    totalScoreColor?: string
    showRating?: boolean
}

export const PlayerInfoBar = (props: PlayerInfoBarProps) => {
    const { player, gameState, gtpos, clock, color, top, overallRecord, view, showRating } = props
    const { layout } = useResponsiveSizings()

    const followList = useStoreState((state) => state.followList)
    const rules = useStoreState((state) => state.rules)
    const currentUserId = useStoreState((state) => state.userData.userData?.id)

    const updateUserFollowList = useStoreActions((state) => state.updateUserFollowList)
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)
    const setSnackbarMessage = useStoreActions((state) => state.setSnackbarMessage)

    const [follow, setFollow] = useState<boolean>(followList.some((item) => item.id === player?.playerId))
    const followEnabled = rules.some((rule) => rule === Rules.FULL_ACCESS || rule === Rules.FOLLOW)

    useEffect(() => {
        setFollow(followList.some((item) => item.id === player?.playerId))
    }, [followList, player?.playerId])

    const defaultRatings: RatingsObject = {
        blitz: {
            rating: 0,
            win: 0,
            draw: 0,
            loss: 0,
            game_type: 'blitz',
            total_games: 0,
            is_provisional: false,
        },
        bullet: {
            rating: 0,
            win: 0,
            draw: 0,
            loss: 0,
            game_type: 'bullet',
            total_games: 0,
            is_provisional: false,
        },
        rapid: {
            rating: 0,
            win: 0,
            draw: 0,
            loss: 0,
            game_type: 'rapid',
            total_games: 0,
            is_provisional: false,
        },
        classic: {
            rating: 0,
            win: 0,
            draw: 0,
            loss: 0,
            game_type: 'classic',
            total_games: 0,
            is_provisional: false,
        },
    }

    const onFollowClick = async () => {
        if (followEnabled) {
            if (follow) {
                await unfollowUser(player?.playerId || '')
            } else {
                const result = await followUser(player?.playerId || '')
                if (typeof result === 'object' && result?.error) {
                    setSnackbarMessage('Limit reached! You can follow up to 50 users')
                    setTimeout(() => {
                        setSnackbarMessage(undefined)
                    }, 2000)
                }
            }
            await updateUserFollowList()
        } else {
            setOverlayTrigger('joinUs')
        }
    }

    // ------------- Subcomponents -------------

    const materialBalance = gameState !== GameViewState.PRE_GAME && gtpos && (
        <MaterialBalance pieces={gtpos.position.board} color={color} size={'22px'} />
    )

    const progress = (
        <Box flexGrow={1} flexDirection={top ? 'column' : 'column-reverse'} display="flex" justifyContent="flex-end">
            {clock}
            <Box overflow="visible">{materialBalance}</Box>
        </Box>
    )

    const playerInfo = player ? (
        <Stack direction="row" gap={1}>
            <Stack gap={0.75} minWidth={'50px'} justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography
                        sx={{
                            flexGrow: 1,
                        }}
                        variant="body1"
                        noWrap
                        textAlign="end"
                    >
                        {player ? player.name : ''}
                    </Typography>
                    {currentUserId !== player.playerId && player.human && view !== PageView.ANALYSIS && (
                        <Tooltip
                            enterTouchDelay={0}
                            title={
                                layout === Layout.DESKTOP
                                    ? follow
                                        ? 'Unfollow'
                                        : 'Follow'
                                    : follow
                                      ? 'Following'
                                      : 'Unfollowed'
                            }
                            placement="top"
                        >
                            <Button
                                variant={follow ? 'outlined' : 'contained'}
                                sx={{
                                    minWidth: 'auto',
                                    width: 'auto',
                                    height: 'auto !important',
                                    minHeight: 'auto',
                                    p: '4px !important',
                                    border: '2px solid',
                                    borderColor: 'primary.main',
                                    '&:hover': {
                                        borderColor: 'primary.dark',
                                    },
                                }}
                                onClick={onFollowClick}
                            >
                                {follow ? <UnfollowIcon /> : <FollowIcon />}
                            </Button>
                        </Tooltip>
                    )}
                </Stack>

                <Box
                    sx={{
                        height: '24px',
                        gap: '8px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    {showRating &&
                        player &&
                        player.human !== false &&
                        player.rating !== undefined &&
                        !Number.isNaN(player.rating) &&
                        Boolean(player.rating) && (
                            <>
                                {player.title && <UserTitle title={player.title} />}
                                {player.ratings && player.ratingType && (
                                    <Rating
                                        data={player.ratings[player.ratingType]}
                                        textSize="body1"
                                        iconSize="medium"
                                    />
                                )}
                            </>
                        )}
                    <>
                        {player.rewards.map((reward, index) => (
                            <img
                                src={reward.url}
                                alt={reward.name}
                                style={{
                                    height: '22px',
                                }}
                            />
                        ))}
                        {player ? (
                            <img
                                src={`/flags/${player.nationality || 'WORLD'}@2x.png`}
                                onError={imgFallback}
                                alt={'flag icon'}
                                style={{
                                    height: '22px',
                                    borderRadius: '.25rem',
                                }}
                            />
                        ) : null}
                    </>
                </Box>
            </Stack>
            {view === PageView.ANALYSIS && !player.avatarUrl ? (
                <div
                    style={{
                        height: '56px',
                        width: '56px',
                        border: '0.063rem solid white',
                        backgroundColor: color === Color.White ? 'white' : 'black',
                    }}
                />
            ) : (
                <ProfileInfo
                    user={{
                        avatarUrl: player.avatarUrl || '',
                        country: player.nationality || '',
                        ratings: player.ratings || defaultRatings,
                        title: player.title || '',
                        id: player.playerId || '',
                        userName: player.name || '',
                        status: 'online',
                        reward: {
                            tier: player.reward?.tier || 0,
                            season: player.reward?.season || '',
                        },
                    }}
                    origin={top ? 'bottom' : 'top'}
                    showOnClick={view === PageView.OBSERVE ? true : false}
                    showOnHover={player.human && view !== PageView.OBSERVE}
                    overallRecord={overallRecord}
                >
                    <FramedAvatar
                        avatar={player.avatarUrl || ''}
                        season={player.reward?.season}
                        reward={player.reward?.tier}
                        sx={avatarStyle}
                    />
                </ProfileInfo>
            )}
            {/* {gameState === GameViewState.GAME_RUNNING && player && latency && (
                <LagIndicator latency={latency} />
            )} */}
        </Stack>
    ) : null

    return (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent={'flex-end'} gap="8px" width="100%">
            {progress}
            {playerInfo}
        </Box>
    )
}
